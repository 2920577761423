<template>
  <div class="gift-info-box">
    <div class="gift_box">
      <div v-show="isSHOW" class="gift_top">
        <div class="gift_img">
          <img :src="giftinfo.img" alt="">
        </div>
        <div class="gift_desc">
          <el-tooltip class="item" effect="dark" :content="giftinfo.gift_name" placement="left-start">
            <h4>{{ giftinfo.gift_name }}</h4>
          </el-tooltip>
          <div class="desc">
            <span>
              <img src="@/assets/image/zlhl.png" alt="">
              {{ giftinfo.quantity }}
            </span>
            <span> 已兑{{ giftinfo.sale_num }}</span>
          </div>
          <p v-if="giftinfo.gift_source === 1">
            {{ giftinfo.info.introduce }}
          </p>
          <div class="num_line">
            <div>
              <el-button
                type="text"
                style="width:20px;font-size:18px;"
                :disabled="true"
                @click="reduce"
              >-</el-button>
              <el-input
                v-model="num"
                type="text"
                style="width:70px;height:35px;"
                :disabled="true"
              ></el-input>
              <el-button
                type="text"
                style="width:20px;font-size:18px;"
                :disabled="true"
                @click="add"
              >+</el-button>
            </div>
            <span style="color:#ee2e2e">限购{{ giftinfo.purchase_num }}件</span>
            <span>剩余{{ giftinfo.num }}件</span>
          </div>
          <div class="dh_line">
            <span style="margin-right:10px;">
              <img src="@/assets/image/zlhl.png" alt="" class="ljdh">
              {{ giftinfo.quantity * num }}
            </span>
            <el-button type="primary" @click="RedeemNow">立即兑换</el-button>
          </div>
        </div>
      </div>
      <!-- 产品详情 -->
      <div class="gift_img_box">
        <div class="gift_img_title">
          <img src="@/assets/image/redline.png">
          <div>产品详情</div>
        </div>
        <div v-if="giftinfo.gift_source === 1" class="gifi_img_content">
          <div v-for="(item, index) in giftimg" :key="index">
            <img :src="item" alt="">
          </div>
        </div>
        <div v-else-if="giftinfo.gift_source === 2" class="gifi_img_content">
          <div style="margin-bottom:15px;" v-html="giftinfo.info.param"></div>
          <div v-html="giftinfo.info.introduction"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { giftinfo, getMyScore } from '@/api/giftinfo.js'
import { getRow } from '@/api/mine.js'
export default {
  data() {
    return {
      num: 1,
      giftinfo: {},
      giftimg: [],
      isSHOW: false,
      tips: ''
    }
  },
  mounted() {
    var banner = document.getElementById('banner')
    banner.style.display = 'none'
    this.getgiftinfo()
    this.getRow()
  },
  methods: {
    reduce() {
      if (this.num === 0) {
        return
      } else {
        this.num--
      }
    },
    add() {
      if (this.num < this.giftinfo.num) {
        this.num++
      } else {
        this.num = this.giftinfo.num
        this.$alert('库存不足', '提示', {})
      }
    },
    getgiftinfo() {
      const info = {
        id: this.$route.query.giftid,
        show: this.$route.query.show
      }
      giftinfo(info)
        .then((res) => {
          if (res.errNo === 0) {
            this.isSHOW = true
          }
          if (res.result === '该商品已下架') {
            this.$alert('该商品已下架', '提示', {})
            return
          }
          if (res.result.img.slice(0, 4) !== 'http') {
            res.result.img = `http://img13.360buyimg.com/n12/${res.result.img}`
          }
          this.giftinfo = res.result
          this.giftimg = res.result.info.content
          this.num = res.result.purchase_num || 1
          console.log(this.num, 'nummm')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 立即兑换
    RedeemNow() {
      if (this.giftinfo.num < 1) {
        return this.$alert('库存不足')
      }
      getMyScore()
        .then((res) => {
          if (this.giftinfo.quantity * this.num > res.result) {
            this.$alert(this.tips + '不足', '提示', {
              showConfirmButton: false
            })
          } else {
            this.$router.push({
              path: 'giftexchange_jdyx',
              query: {
                giftid: this.$route.query.giftid,
                num: this.num
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getRow() {
      getRow({ type: 1 }).then(res => {
        if (res.errNo === 0) {
          const origin = window.location.origin
          // if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
          //   this.tips = '注考币'
          // } else {
            this.tips = res.result.name
          // }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.gift-info-box {
  width: 100%;
  margin-top: 20px;
  padding-top: 40px;
  .gift_box {
    margin: 0px auto;
    background: white;
  }
  .gift_top {
    overflow: hidden;
    .gift_img {
      float: left;
      width: 400px;
      height: 355px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .gift_desc {
      float: right;
      width: 600px;
      height: 100%;
      text-align: left;
      h4 {
        font-size: 22px;
        color: #333;
        font-weight: bold;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > .desc {
        height: 25px;
        line-height: 25px;
        margin: 10px 0px 15px 0px;
        font-size: 15px;
        img {
          height: 22px;
          width: 20px;
          vertical-align: top;
        }
        span {
          margin-right: 30px;
        }
      }
      p {
        font-size: 12px;
        // height: 92px;
        line-height: 23px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        margin-bottom: 15px;
      }
    }
  }
}
/deep/.el-input__inner {
  height: 35px;
  line-height: 35px;
  text-align: center;
}
/deep/.el-button--text {
  color: #333;
}
.num_line {
  font-size: 12px;
  > div {
    display: inline-block;
    margin-right: 10px;
  }
  span {
    margin: 0 10px;
  }
}
.dh_line {
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  margin: 30px 0px;
  .ljdh {
    height: 22px;
    width: 20px;
    margin-top: -5px;
  }
}
.gift_img_title {
  position: relative;
  margin: 20px 0px;
  img {
    margin: 30px 0 20px;
    width: 100%;
    height: 2px;
  }
  div {
    height: 52px;
    width: 146px;
    line-height: 52px;
    color: #ee2e2e;
    position: absolute;
    margin-left: -73px;
    font-size: 22px;
    top: 0px;
    background: white;
    font-weight: bold;
    left: 50%;
  }
}
.gifi_img_content {
  text-align: center;
  padding-bottom: 30px;
  img {
    max-width: 100%;
    height: auto;
  }
}
/deep/.el-message-box__message p {
  text-align: center !important;
  font-size: 16px;
  margin-bottom: 10px;
}
</style>
